export const bootChannelTalk = (userId = "") => {
  if (typeof window !== "undefined" && !process.env.IS_DEV) {
    window.ChannelIO(
      "boot",
      {
        pluginKey: "74fefd0d-c880-4ec7-b06f-d09eeba19b43",
        customLauncherSelector: "#footer-channel-button, .channel-button",
        memberId: userId,
        hideChannelButtonOnBoot: true,
      },
      function onBoot(error, user) {
        if (error) {
          console.error(error);
        } else if (!process.env.IS_PROD) {
          console.log("boot success ", user);
        }
      }
    );
  }
};
